import { ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
    ComputeBudgetProgram,
    Connection,
    PublicKey,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import invariant from 'tiny-invariant';
import {
    MAX_PRIORITY_FEE,
    MIN_PRIORITY_FEE,
    PRIORITY_FEE_MULTIPLIER,
} from '../constants';

const getCUsForTx = async (
    connection: Connection,
    latestBlockhash: Awaited<ReturnType<typeof connection.getLatestBlockhash>>,
    txs: TransactionInstruction[],
    payerKey: PublicKey,
) => {
    const messageV0 = new TransactionMessage({
        payerKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: txs,
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    const simulation = await connection.simulateTransaction(transaction);
    const CUs =
        !simulation.value.unitsConsumed || simulation.value.unitsConsumed == 0
            ? 1.4e6
            : simulation.value.unitsConsumed;
    console.log('Estimated CUs:', CUs);
    return CUs;
};

const estimatePriorityFees = async (
    connection: Connection,
    instructions: TransactionInstruction[],
    multiplier: number = 1,
    maxPriorityFee = 100000,
    minPriorityFee = 1,
): Promise<TransactionInstruction> => {
    const accounts = instructions.flatMap((e) => e.keys).map((e) => e.pubkey);

    const prioFees = await connection.getRecentPrioritizationFees({
        lockedWritableAccounts: accounts,
    });

    // Sort in descending order on slot so we get the latest data
    prioFees.sort((a, b) => b.slot - a.slot);

    // Estimate priority fee by taking the avg of recent fees and scale with the provider multiplier
    // to control speed/likelyhood of success
    const estimatedPrioFee = Math.floor(
        (multiplier *
            prioFees
                .slice(0, 10)
                .map((e) => e.prioritizationFee)
                .reduce((a, b) => a + b, 0)) /
            prioFees.length,
    );

    // Apply min and max cap if needed
    const prioFee = Math.max(
        minPriorityFee,
        Math.min(estimatedPrioFee, maxPriorityFee),
    );

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: prioFee,
    });

    console.log(`Estimated priority fee: ${prioFee} µlamports`);
    return addPriorityFee;
};

export const createVersionedTransaction = async (
    connection: Connection,
    txs: TransactionInstruction[],
    payerKey: PublicKey,
    addCUs?: boolean,
    minimumCU?: number,
    doNotAddPriorityFee?: boolean,
) => {
    const latestBlockhash = await connection.getLatestBlockhash('finalized');

    if (!doNotAddPriorityFee) {
        const priorityFeeIx = await estimatePriorityFees(
            connection,
            txs,
            PRIORITY_FEE_MULTIPLIER,
            MAX_PRIORITY_FEE,
            MIN_PRIORITY_FEE,
        );
        txs.unshift(priorityFeeIx);
    }

    if (addCUs) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const estimatedCUs = await getCUsForTx(
            connection,
            latestBlockhash,
            txs,
            payerKey,
        );
        const CUs = Math.max(minimumCU ? minimumCU : 28000, estimatedCUs); // Always have at least 28k or minimumCU CU
        txs.unshift(
            ComputeBudgetProgram.setComputeUnitLimit({
                units: CUs + 3000, // +3k for safety and the CU limit ix itself
            }),
        );
    }

    const messageV0 = new TransactionMessage({
        payerKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: txs,
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    return { transaction, latestBlockhash };
};

export const dedupeDuplicateATAIxs = (
    ixs: TransactionInstruction[],
): TransactionInstruction[] => {
    const seenATAs = new Set<string>();
    return ixs
        .map((ix) => {
            const programId = ix.programId;
            if (programId.equals(ASSOCIATED_TOKEN_PROGRAM_ID)) {
                const ataKey = ix.keys[1]?.pubkey.toString();
                invariant(ataKey, 'ata key must exist');
                if (seenATAs.has(ataKey)) {
                    return null;
                }
                seenATAs.add(ataKey);
            }
            return ix;
        })
        .filter((ix): ix is TransactionInstruction => !!ix);
};
