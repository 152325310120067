exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dapp-airdrop-tsx": () => import("./../../../src/pages/dapp/airdrop.tsx" /* webpackChunkName: "component---src-pages-dapp-airdrop-tsx" */),
  "component---src-pages-dapp-claim-tsx": () => import("./../../../src/pages/dapp/claim.tsx" /* webpackChunkName: "component---src-pages-dapp-claim-tsx" */),
  "component---src-pages-dapp-direct-tsx": () => import("./../../../src/pages/dapp/direct.tsx" /* webpackChunkName: "component---src-pages-dapp-direct-tsx" */),
  "component---src-pages-dapp-index-tsx": () => import("./../../../src/pages/dapp/index.tsx" /* webpackChunkName: "component---src-pages-dapp-index-tsx" */),
  "component---src-pages-dapp-info-tsx": () => import("./../../../src/pages/dapp/info.tsx" /* webpackChunkName: "component---src-pages-dapp-info-tsx" */),
  "component---src-pages-dapp-pools-tsx": () => import("./../../../src/pages/dapp/pools.tsx" /* webpackChunkName: "component---src-pages-dapp-pools-tsx" */),
  "component---src-pages-dapp-unstake-tsx": () => import("./../../../src/pages/dapp/unstake.tsx" /* webpackChunkName: "component---src-pages-dapp-unstake-tsx" */),
  "component---src-pages-dapp-validator-dashboard-tsx": () => import("./../../../src/pages/dapp/validatorDashboard.tsx" /* webpackChunkName: "component---src-pages-dapp-validator-dashboard-tsx" */),
  "component---src-pages-dapp-validators-tsx": () => import("./../../../src/pages/dapp/validators.tsx" /* webpackChunkName: "component---src-pages-dapp-validators-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

